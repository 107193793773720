import '../styles/global.scss';
import '../styles/pages/trial.scss';
import {BigLogoImage, serv1, freeFrame, Star, Sign, Shield, Phone1, Phone2, Phone3, Phone4} from "../images";

import {HeadFC, Link} from "gatsby";

import React, { useEffect, useState } from 'react'
import TimerCTA from '../components/TimerCTA';
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import useAnalytics from "../hooks/useAnalytics";

const AppstoreTrial = () => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);
    const [eventStatus, setEventStatus] = useState(false);

    useEffect( () => {
        if(!eventStatus){
            // fbq("track", "AddToCart");
            // console.log('fb track AddToCart');
            setEventStatus(true);
        }
    }, [eventStatus]);


    return (
        <>
            <div className="MobileWrap">
                <div className="Trial">
                    <div className="containerSpec">
                        <div className="TrialBannerBox">
                            <a href="" className="TrialBannerLogo">
                                <img src={BigLogoImage} alt="" />
                            </a>
                            <h1 className="TrialBannerTitle">
                                <span>Education</span> and <span>Productivity</span><br/> in one Platform
                            </h1>
                        </div>
                    </div>

                    <div className="TrialServ">
                        <div className="containerSpec">
                            <div className="TrialServBox">
                                <h2 className="TrialServTitle">Useful tools in one place:</h2>
                                <div className="TrialServItem flex">
                                    <img src={serv1} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER Platform</span>
                                        <p>Your courses, tasks, notes, and finances. All linked in one place.</p>
                                    </div>
                                </div>
                                <div className="TrialScreens">
                                    <div className="TrialScreen">
                                        <img src={Phone1} alt="" className="TrialFrame" />
                                        <div className="TrialSInfo">
                                            <span>AEER Education</span>
                                            <p>Learn in-demand skills with over 50 interactive Soft Skill training programs</p>
                                        </div>
                                    </div>
                                    <div className="TrialScreen reverse">
                                        <img src={Phone3} alt="" className="TrialFrame" />
                                        <div className="TrialSInfo">
                                            <span>AEER Finances</span>
                                            <p>Get your finances on track! Identify sources of income, control expenses and plan your budget</p>
                                        </div>
                                    </div>
                                    <div className="TrialScreen">
                                        <img src={Phone4} alt="" className="TrialFrame" />
                                        <div className="TrialSInfo">
                                            <span>AEER - Tasks and Goals</span>
                                            <p>Set tasks and goals by one click</p>
                                        </div>
                                    </div>
                                    <div className="TrialScreen reverse">
                                        <img src={Phone2} alt="" className="TrialFrame" />
                                        <div className="TrialSInfo">
                                            <span>AEER - Dairy</span>
                                            <p>Note important decisions and actions into private business diary</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="TrialPeriod">
                        <div className="containerSpec">
                            <div className="TrialPeriodBox">
                                <div className="TrialPeriodLeft">
                                    <span>7-days trial period</span>
                                </div>
                                <div className="TrialPeriodRight">
                                    <img src={freeFrame} alt="" />
                                </div>
                            </div>
                            <div className="TrialPeriodCenter">
                                <Link to="/payment" className="TrialPeriodBtn">GET FREE ACCESS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="TrialFeed">
                        <div className="containerSpec">
                            <h2 className="TrialFeedTitle">Users love our app</h2>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>Thank you so much!</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>25 Jul</span>
                                        <p>Alex</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.</p>
                                </div>
                            </div>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>The APP helped me</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>27 Jul</span>
                                        <p>Jhon</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>Instead of boosting my productivity, my previous productivity apps had so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomlishent and confidence that everything’s organized</p>
                                </div>
                            </div>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>Took a course, like it</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>3 Aug</span>
                                        <p>Jesika</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TrialAccess">
                        <div className="containerSpec">
                            <div className="TrialAccessBox">
                                <div className="TrialAccessHead">
                                    <img src={Shield} alt="" />
                                    <span>30-Day Money-Back Guarantee</span>
                                </div>
                                <div className="TrialAccessText">
                                    <p>We believe that our plan may work for you and you’ll get visible results in 4 weeks! We even are ready to return your money back if you don’t see visible results and can demonstrate that you followed our plan. Find more about applicable limitations in our <a href="">money-back policy</a> </p>
                                </div>
                                <img src={Sign} alt="" className="TrialAccessSign" />
                            </div>
                            <Link to="/payment" className="TrialPeriodBtn TrialAccessBtn">GET FREE ACCESS</Link>
                        </div>
                    </div>
                    <div className="TrialFooter">
                        <div className="containerSpec">
                            <p className="TrialFooterText">Your free trial starts today and ends on 10.11.2022. Then you will be charged $0.48 per day.  No compromises. You can cancel at any time.</p>
                            <p className="TrialFooterCopy">© 2022 AEER Platform 8 THE GREEN STE A DOVER, DE 19901</p>
                        </div>
                    </div>
                    <div className="AppstoreSpace"></div>
                    <TimerCTA timerVal="" link="/payment" />
                </div>
            </div>
        </>
    )
}

export default AppstoreTrial;

export const Head: HeadFC = () => (
    <>
        <title>Upgrade yourself - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
